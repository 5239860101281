import Vue from 'vue';
import VueRouter from 'vue-router';
import {cookieUtil} from "@/util/cookieUtil";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/page/proxy/index.vue'),
        name: 'Agent'
    },
    {
        path: '/login',
        component: () => import('@/page/login/login.vue'),
        name: 'Login'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes: routes,
})

router.beforeEach((to, from, next) => {
    const adminToken = cookieUtil.getCookie('token')

    if (to.path === '/login') {
        if (adminToken) {
            next('/');
        } else {
            next();
        }
    } else {
        if (adminToken) {
            next();
        } else {
            next('/login');
        }
    }
});

export default router
